<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <Toast />
        <Toolbar class="mb-4">
          <template v-slot:start>
            <div class="my-2">
              <Button label="Novo Sub Mercado" icon="pi pi-plus" class="p-button-success mr-2" @click="openNew" />
              <Button label="Apagar" icon="pi pi-trash" class="p-button-danger" @click="confirmDeleteSelected" :disabled="!selectUnidadesSubMercado || !selectUnidadesSubMercado.length" />
            </div>
          </template>

          <template v-slot:end>
            <!-- <FileUpload mode="basic" accept="image/*" :maxFileSize="1000000" label="Import" chooseLabel="Import" class="mr-2 inline-block" />-->
            <Button label="Exportar" icon="pi pi-upload" class="p-button-help" @click="exportCSV($event)" />
          </template>
        </Toolbar>

        <!-- Tabela de Unidades -->
        <DataTable ref="dt" :value="subMercados" v-model:selection="selectUnidadesSubMercado" dataKey="id" :paginator="true" :rows="50" :filters="filters" 
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5, 10, 25]"
         currentPageReportTemplate="Mostrando {first} de {last} para {totalRecords} resultado(s)" responsiveLayout="scroll">
          <template #header>
            <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
              <h5 class="m-0">Gerenciar Sub Mercados</h5>
              <span class="block mt-2 md:mt-0 p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="filters['global'].value" placeholder="Buscar" />
              </span>
            </div>
          </template>

          <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
          <Column field="id" header="ID" :sortable="true" headerStyle="width:14%; min-width:5rem;">
            <template #body="slotProps">
              <span class="p-column-title">Id</span>
              {{ slotProps.data.id }}
            </template>
          </Column>

          <Column field="descricao" header="Sub Mercado" :sortable="true" headerStyle="width:30%; min-width:10rem;">
            <template #body="slotProps">
              <span class="p-column-title">Sub Mercado</span>
              {{ slotProps.data.descricao }}
            </template>
          </Column>

          <Column headerStyle="min-width:10rem;">
            <template #body="slotProps">
              <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="editProduct(slotProps.data)" />
              <Button icon="pi pi-trash" class="p-button-rounded p-button-warning mt-2" @click="apagarSubMercadoModal(slotProps.data)" />
            </template>
          </Column>
        </DataTable>
        
        <!-- Modal Adição/Edição de Tipo de Sub Mercado -->
        <Dialog v-model:visible="productDialog" :style="{ width: '450px' }" header="Editar Sub Mercado" :modal="true" class="p-fluid">
          <div class="field">
            <label for="name">Sub Mercado</label>
            <InputText id="bome" v-model.trim="subMercado.descricao" required="true" autofocus :class="{ 'p-invalid': submitted && !subMercado.descricao}" />
            <small class="p-invalid" v-if="submitted && !subMercado.descricao">Descrição é obrigatória.</small>
          </div>
    
          <template #footer>
            <Button label="Cancelar" icon="pi pi-times" class="p-button-secondary p-button-sm" @click="hideDialog" />
            <Button label="Salvar" icon="pi pi-check" class="p-button-success p-button-sm" @click="salvarSubMercado" />
          </template>
        </Dialog>

        <!-- Modal deletar um tipo de sub mercado -->
        <Dialog v-model:visible="deleteProductDialog" :style="{ width: '450px' }" :modal="true">
          <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span v-if="subMercado">Certeza que deseja apagar este item: <b>{{ subMercado.descricao }}</b>?</span>
          </div>
          <template #footer class="align-items-center">
            <Button label="Não" icon="pi pi-times" class="p-button-secondary p-button-sm" @click=" deleteProductDialog = false" />
            <Button label="Sim" icon="pi pi-check" class="p-button-danger p-button-sm" @click="apagarSubMercado" />
          </template>
        </Dialog>

        <!-- Modal deletar vários selecionados -->
        <Dialog v-model:visible="deleteProductsDialog" :style="{ width: '450px' }" header="" :modal="true">
          <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span v-if="subMercado">Apagar Sub Mercado?</span>
          </div>
          <template #footer>
            <Button label="Não" icon="pi pi-times" class="p-button-text" @click="deleteProductsDialog = false" />
            <Button label="Sim" icon="pi pi-check" class="p-button-text" @click="deleteSelectedProducts" />
          </template>
        </Dialog>

      </div>
    </div>
  </div>
</template>

<script>

  import { FilterMatchMode } from "primevue/api";

  export default {
    data() {
      return {
        subMercados: [],
        subMercado: {},
        selectUnidadesSubMercado: null,
        selectedProducts: null,
        productDialog: false,
        sub_mercado: [],
        deleteProductDialog: false,
        filters: {},
        submitted: false,
        deleteProductsDialog: false,
      
      };
    },
    // productService: null,
    created() {
      this.initFilters();
    },
    mounted() {
      this.listarSubMercados();
    },


    methods: {
      // listar tipos de sub mercado do end-point
      listarSubMercados(){
        this.axios
          .post("/unidades/dados/tipos-sub-mercado/listar")
          .then((response) => {
            this.subMercados = response.data.dados;
          })
          .catch(() => {
            self.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            });
          });
      },

      openNew() {
        this.subMercado = {};
        this.submitted = false;
        this.productDialog = true;
      },

      editProduct(subMercado) {
        this.productDialog = true;
        this.subMercado = { ...subMercado };
      },

    

      hideDialog() {
        this.productDialog = false;
        this.submitted = false;
      },


      // Cria / Editar  -> tipos de sub mercado
      salvarSubMercado() {
        this.submitted = true;

        if (this.subMercado.id) {
          // Atualizar um tipo de sub mercado
          this.axios
            .post("/unidades/dados/tipos-sub-mercado/editar", this.subMercado)
            .then(() => {

              this.productDialog = false;
              this.subMercado = {};
              this.listarSubMercados();
            })
            .catch(() => {
              this.$toast.add({
                severity: "error",
                summary: "Ops!",
                detail: "Chamar o T.I.",
                life: 3000,
              });
            });
        } else {
          // Cadastrar novo tipo de sub mercado
          this.axios
            .post("/unidades/dados/tipos-sub-mercado/adicionar", this.subMercado)
            .then(() => {
              this.productDialog = false;
              this.subMercado = {};
              this.listarSubMercados();
            })
            .catch(() => {
              self.$toast.add({
                severity: "error",
                summary: "Ops!",
                detail: "Chamar o T.I.",
                life: 3000,
              });
            });
        }
      },

    
      //Apaga um tipo de sub mercado
      apagarSubMercado() {
        this.axios
          .post("/unidades/dados/tipos-sub-mercado/apagar", this.subMercado)
          .then(() => {
            
            this.deleteProductDialog = false;
            this.subMercado = {};
            this.$toast.add({
              severity: "success",
              summary: "Sucesso",
              detail: "Sub mercado apagado com sucesso!",
              life: 3000,
            });
            this.listarSubMercados();
          })
          .catch(() => {
            
            self.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            });
          });
      },

      //Apaga o Modal que abre ao selecionar que deseja apagar um tipo de sub mercado
      apagarSubMercadoModal(subMercado) {
        this.subMercado = subMercado;
        this.deleteProductDialog = true;
      },

      // botao de excluir selecionados
      confirmDeleteSelected() {
        this.deleteProductsDialog = true;
      },

      
      // Faz o delete de vários items selecionados no datatable
      deleteSelectedProducts() {
        this.subMercados = this.subMercados.filter(
          (val) => !this.selectUnidadesSubMercado.includes(val)
        );
 
        let obj = this.selectUnidadesSubMercado;
        Object.keys(obj).forEach((key) => {
          this.axios
            .post("/unidades/dados/tipos-sub-mercado/apagar", obj[key])
            .then(() => {
              this.deleteProductDialog = false;
              this.$toast.add({
                severity: "success",
                summary: "Sucesso",
                detail: "Sub Mercado apagado com sucesso!",
                life: 3000,
              });
            })
            .catch(() => {
              this.$toast.add({
                severity: "error",
                summary: "Ops!",
                detail: "Chamar o T.I.",
                life: 3000,
              });
            });
        });
 
        this.deleteProductsDialog = false;
        this.selectUnidadesSubMercado = null;
        this.$toast.add({
          severity: "success",
          summary: "Sucesso",
          detail: "Products Deleted",
          life: 3000,
        });
      },
     
     exportCSV() {
       this.$refs.dt.exportCSV();
      },
      
      
      initFilters() {
        this.filters = {
          global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        };
      },
      
    },
  };


</script>
<style scoped lang="scss">
@import "../../assets/demo/badges.scss";
</style>
